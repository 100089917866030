import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import _ from 'lodash';
import Img from 'gatsby-image';
import Masonry from 'react-masonry-component';
import '../../styles/components/portfolio-gallery/portfolio-grid.scss';
import PortfolioGridControls from './PortfolioGridControls';
import PortfolioNavigationInformation from './PortfolioNavigationInformation';
import PortfolioGridOverlay from './PortfolioGridOverlay';
import LocalizedLink from '../Utils/LocalizedLink';
import {isTouchDevice} from '../../utils/helpers';

export default props => (
  <StaticQuery
    query={graphql`
        query {
            allMarkdownRemark{
        edges{
          node{
            id
            frontmatter{
              language,
              path,
              tags,
              template,
              productType,
              productTypeShort,
              segmentTag,
              areaTag,
              solutionTag,
              title,
              listed,
              image {
              publicURL
              childImageSharp {
                fluid(maxWidth:1000) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            }
          }
        }
      }
        }
      `}
    render={data => <PortfolioGrid data={data} {...props} />}
  />
);

/*
MASONRY OPTIONS INIT


MASONRY CONTROLS
        - accessing instances on events
     
        prop
      onImagesLoaded={imagesLoadedInstance =>
      this.handleImagesLoaded (imagesLoadedInstance)}
              
                     
        - accessing masonry instance
         prop 
                ref={function (c) {
                this.masonry = this.masonry || c.masonry;
              }.bind (this)}

        accessing on mount example
              
              componentDidMount(){
                  componentDidMount() {
        this.masonry.on('layoutComplete', this.handleLayoutComplete);
    },
              }

*/

const masonryOptions = {
  transitionDuration: 1200, // time to appear images miliseconds
  columnWidth: '.grid-sizer',
  itemSelector: '.portfolio-image',
  percentPosition: true,
  gutter: 10, // space between items in pixels
  stagger: 300, //loading staggering in miliseconds
};

class PortfolioGrid extends React.Component {
  state = {
    iconSelected: 'all',
    iconHover: null,
    tagSelected: '',
    searchValue: '',
    tapTouch: false,
    projectClicked: '',
  };

  componentDidUpdate () {
    //short circuit if product type selected comes from cases side menu
    if (this.props.productTypeShortSideMenu) {
      this.changeTagSelected (this.props.productTypeShortSideMenu);
      this.selectionPicker (this.props.productTypeShortSideMenu);
      this.fillColor (this.props.productTypeShortSideMenu);
      this.colorHoverToggler (this.props.productTypeShortSideMenu);
      this.props.resetProductTypeShortSideMenu ();
    }
  }

  searchValueSetter = value => this.setState ({searchValue: value});

  changeTagSelected = tagSelected => this.setState ({tagSelected: tagSelected});

  selectionPicker = productIcon => {
    if (this.state.iconSelected !== productIcon)
      this.setState ({iconSelected: productIcon});
  };

  colorHoverToggler = productIcon => {
    this.setState ({iconHover: productIcon});
  };

  colorHoverTogglerClear = productIcon => {
    this.setState ({
      iconHover: null,
    });
  };

  fillColor = productIcon => {
    if (
      this.state.iconSelected === productIcon ||
      this.state.iconHover === productIcon
    ) {
      return '#0033A0';
    }
    return '#9E9E9E';
  };

  clickOnTouchDevice = d => {
    this.setState ({
      tapTouch: true,
      projectClicked: d.title,
    });
  };

  render () {
    const data = this.props.data.allMarkdownRemark.edges;

    const isTouchClick = this.state.tapTouch ? 'click-touch-device' : '';

    let images = _.chain (data)
      .map (d => d.node.frontmatter)
      .filter (d => d['language'] === this.props.locale.locale)
      .filter (d => d['listed'] === true)
      .filter (d => d['template'] !== 'LP')
      .filter (
        d =>
          this.state.iconSelected === 'all' ||
            this.state.iconSelected === 'search'
            ? true
            : d['productTypeShort'] === this.state.iconSelected
      )
      .filter (
        d =>
          this.state.iconSelected === 'search' && this.state.tagSelected !== ''
            ? d['solutionTag'].indexOf (this.state.tagSelected) > -1
            : true
      )
      .map (d => (
        <div
          className="portfolio-image"
          onClick={() =>
            typeof window !== `undefined` &&
            isTouchDevice () &&
            this.clickOnTouchDevice (d)}
          key={d.image.publicURL}
        >
          <Img
            className=" portfolio-image-component"
            fluid={d.image.childImageSharp.fluid}
          />
          <LocalizedLink
            to={d['path']}
            locale={d['language']}
            key={d.image.publicURL}
          >
            <div
              className={
                this.state.projectClicked === d['title']
                  ? 'grid-item-overlay ' + isTouchClick
                  : 'grid-item-overlay'
              }
            >
              <PortfolioGridOverlay
                title={d['title']}
                segmentTag={d['segmentTag']}
                areaTag={d['areaTag']}
                solutionTag={d['solutionTag']}
                productType={d['productType']}
                productTypeShort={d['productTypeShort']}
              />
            </div>
          </LocalizedLink>
        </div>
      ))
      .value ();

    return (
      <div className="portfolio-container">
        <div className="portfolio-controls-container">
          <PortfolioGridControls
            messages={this.props.messages}
            selectionPicker={this.selectionPicker}
            iconHover={this.state.iconHover}
            colorHoverToggler={this.colorHoverToggler}
            colorHoverTogglerClear={this.colorHoverTogglerClear}
            fillColor={this.fillColor}
            currentIconSelected={this.state.iconSelected}
            searchMessages={this.props.messages.search}
            searchValue={this.state.searchValue}
            data={data}
            locale={this.props.locale.locale}
            searchValueSetter={this.searchValueSetter}
            changeTagSelected={this.changeTagSelected}
            tagSelected={this.state.tagSelected}
            iconSelected={this.state.iconSelected}
          />
        </div>
        <div className="portfolio-navigation-information-container">
          <PortfolioNavigationInformation
            currentIconSelected={this.state.iconSelected}
            messages={this.props.messages}
            data={data}
            locale={this.props.locale.locale}
            changeTagSelected={this.changeTagSelected}
          />
        </div>
        <div className="portfolio-gallery-wrapper">
          <Masonry
            className={'my-gallery-class'}
            elementType={'div'}
            options={masonryOptions}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
          >
            <div className="grid-sizer" />
            {images}
          </Masonry>
        </div>
      </div>
    );
  }
}

/*
    let images = this.props.data.allMarkdownRemark.edges.map (img => {
      return <Img fixed={img.node.frontmatter.image.childImageSharp.fixed} />;
    });

  http://stayregular.net/blog/adding-images-and-media-to-your-gatsbyjs-blog


*/
