import React from 'react';
import '../../styles/components/portfolio-gallery/portfolio-grid-overlay.scss';
import ManagementandLogistics from '../ProductTypeIcons/ManagementandLogistics';
import ImmersiveandVirtualPresentations
  from '../ProductTypeIcons/ImmersiveandVirtualPresentations';
import InteractiveExperiences from '../ProductTypeIcons/InteractiveExperiences';
import AdvancedVisualContent from '../ProductTypeIcons/AdvancedVisualContent';
import '../../styles/utils/general.scss';

function Logo (props) {
  let logo = null;

  switch (props.productTypeShort) {
    case 'mls':
      logo = <ManagementandLogistics iconFill={'white'} />;
      break;
    case 'avc':
      logo = <AdvancedVisualContent iconFill={'white'} />;
      break;
    case 'ie':
      logo = <InteractiveExperiences iconFill={'white'} />;
      break;
    case 'ivp':
      logo = <ImmersiveandVirtualPresentations iconFill={'white'} />;
      break;
    default:
      console.error (
        'error loading product type. check producTypeShort in markup'
      );
  }

  return logo;
}

const PortfolioGridOverlay = props => {
  const productTypeShort = props.productTypeShort;

  return (
    <div className="overlay-group">
      <div className="overlay-title">
        <h2 style={{marginBottom: '13px'}}>{props.title}</h2>
      </div>
      <div className="product-type-container typo-body2-white ">
        <div className="overlay-logo">
          <Logo productTypeShort={productTypeShort} />
        </div>
        <div className="overlay-product-type typo-body2-white">
          {props.productType}
        </div>
      </div>
      <div className="overlay-tags-container typo-body2-white ">
        <div className="overlay-segment-tag">{props.segmentTag}</div>
        <div className="overlay-area-tag">{props.areaTag}</div>
      </div>
    </div>
  );
};

export default PortfolioGridOverlay;
