import React, {useState} from 'react';
import '../../styles/components/portfolio-gallery/portfolio-grid-controls.scss';
import ProductMessage from '../Utils/ProductMessage';
import Autocomplete from 'react-autocomplete';
import _ from 'lodash';
import ManagementandLogistics from '../ProductTypeIcons/ManagementandLogistics';
import ImmersiveandVirtualPresentations
  from '../ProductTypeIcons/ImmersiveandVirtualPresentations';
import InteractiveExperiences from '../ProductTypeIcons/InteractiveExperiences';
import AdvancedVisualContent from '../ProductTypeIcons/AdvancedVisualContent';
import LogoLoader from '../../components/Utils/LogoLoader';
import {isSmallScreen} from '../../utils/helpers';

//component for drawing SVG of ' All Cases ' icon
function IconAllCases (props) {
  const iconFill = props.iconFill;

  return (
    <svg
      className="all-cases"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      aria-labelledby="title"
    >
      <title id="title">IconAllCases</title>
      <path
        fill={iconFill}
        d="M4,9 L12,9 L12,17 L4,17 L4,9 Z M4,20 L12,20 L12,28 L4,28 L4,20 Z M14,9 L22,9 L22,17 L14,17 L14,9 Z M14,20 L22,20 L22,28 L14,28 L14,20 Z M24,9 L32,9 L32,17 L24,17 L24,9 Z M24,20 L32,20 L32,28 L24,28 L24,20 Z"
      />
    </svg>
  );
}

//component for drawing SVG of ' Search ' icon

function Search (props) {
  const iconFill = props.iconFill;

  return (
    <svg
      className="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      aria-labelledby="title"
    >
      <title id="title">Search Icon</title>
      <path
        fill={iconFill}
        d="M24.331716,22.1823785 L31.4075656,29.2179952 L29.2179952,31.4075656 L21.9942024,24.2248468 C20.2698275,25.3476216 18.2110993,26 16,26 C9.92486775,26 5,21.0751322 5,15 C5,8.92486775 9.92486775,4 16,4 C22.0751322,4 27,8.92486775 27,15 C27,17.7450468 25.9944988,20.2552462 24.331716,22.1823785 Z M16,23 C20.418278,23 24,19.418278 24,15 C24,10.581722 20.418278,7 16,7 C11.581722,7 8,10.581722 8,15 C8,19.418278 11.581722,23 16,23 Z"
      />
    </svg>
  );
}

//component which defines search form behavior

function SearchFormAutoComplete (props) {
  const tagsFlatList = _.chain (props.data)
    .map (d => d.node.frontmatter)
    .filter (
      d =>
        d['language'] === props.locale &&
        d['listed'] === true &&
        d['template'] !== 'LP'
    )
    .map (d => d.solutionTag)
    .flatten ()
    .value ();

  let value = props.searchValue;

  const tagsFlatNoDups = _.uniq (tagsFlatList);

  var selectHighlihted = function (item) {
    if (value === '') return item;

    var newText = String (item).replace (
      new RegExp (value, 'gi'),
      "<span class='ui-state-highlight'}>$&</span>"
    );

    return newText;
  };

  var menuStyle = {
    borderColor: 'rgb(0,51,160)',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
    background: 'rgba(255, 255, 255, 1)',
    paddingTop: '17px',
    paddingBottom: '17px',
    fontSize: '90%',
    overflow: 'auto',
    position: 'absolute',
    top: '36px',
    left: 0,
    width: '100%',
  };

  return (
    <div className="search-box-form">
      <Autocomplete
        items={tagsFlatNoDups}
        wrapperProps={{className: 'wrapper-autocomplete'}}
        shouldItemRender={(item, value) =>
          item.toLowerCase ().indexOf (value.toLowerCase ()) > -1}
        getItemValue={item => item}
        renderMenu={(
          items,
          value,
          style //TODO solve key
        ) => (
          <div
            className="menu"
            style={{...style, ...menuStyle}}
            children={items}
          />
        )}
        renderItem={(item, highlighted) => (
          <div
            style={{
              background: highlighted
                ? 'rgba(79, 106, 203,0.1)'
                : 'transparent',
              paddingRight: '12px',
              paddingLeft: '12px',
            }}
          >
            <div
              //change here highlight where the cursor is
              dangerouslySetInnerHTML={{__html: selectHighlihted (item)}}
            />
          </div>
        )}
        renderInput={args => (
          <div className="search-input-wrapper">
            <input {...args} />
            <label htmlFor="input" className="control-label">
              {props.searchMessage}
            </label>
            <i className="bar" />
          </div>
        )}
        value={value}
        onChange={e => props.searchValueSetter (e.target.value)}
        onSelect={value => props.changeTagSelected (value)}
      />
    </div>
  );
}

const ProductGridControls = props => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState (false);
  const [
    iconSelectedBeforeSearchClick,
    setIconSelectedBeforeSearchClick,
  ] = useState ();

  var legendToggler = props.currentIconSelected === props.iconHover
    ? 'is-hidden'
    : '';

  // initiate as true so it starts correctly on mobile in production build

  var _isSmallScreen = true;

  _isSmallScreen = isSmallScreen ();

  return (
    <div className="portfolio-grid-menu">

      <div className="product-type-controls">
        <div
          className="product-type-controls-item"
          onClick={() => setIsMobileMenuActive (!isMobileMenuActive)}
          style={
            !_isSmallScreen || isMobileMenuActive
              ? {display: 'none'}
              : {display: 'inherit'}
          }
        >
          <LogoLoader
            productTypeShort={
              props.iconSelected === 'search'
                ? iconSelectedBeforeSearchClick
                : props.iconSelected
            }
            fill={props.fillColor (props.iconSelected)}
          />
        </div>
        <div
          className={'product-type-controls-item'}
          style={
            _isSmallScreen && !isMobileMenuActive
              ? {display: 'none'}
              : {display: 'inherit'}
          }
          onClick={() => {
            props.selectionPicker ('all');
            setIsMobileMenuActive (!isMobileMenuActive);
          }}
          onMouseEnter={() => props.colorHoverToggler ('all')}
          onMouseLeave={() => props.colorHoverTogglerClear ('all')}
        >
          <IconAllCases iconFill={props.fillColor ('all')} />
        </div>
        <div
          className={'product-type-controls-item'}
          style={
            _isSmallScreen && !isMobileMenuActive
              ? {display: 'none'}
              : {display: 'inherit'}
          }
          onClick={() => {
            props.selectionPicker ('mls');
            setIsMobileMenuActive (!isMobileMenuActive);
          }}
          onMouseEnter={() => props.colorHoverToggler ('mls')}
          onMouseLeave={() => props.colorHoverTogglerClear ('mls')}
        >
          <ManagementandLogistics iconFill={props.fillColor ('mls')} />
        </div>

        <div
          className={'product-type-controls-item'}
          style={
            _isSmallScreen && !isMobileMenuActive
              ? {display: 'none'}
              : {display: 'inherit'}
          }
          onClick={() => {
            props.selectionPicker ('ivp');
            setIsMobileMenuActive (!isMobileMenuActive);
          }}
          onMouseEnter={() => props.colorHoverToggler ('ivp')}
          onMouseLeave={() => props.colorHoverTogglerClear ('ivp')}
        >
          <ImmersiveandVirtualPresentations
            iconFill={props.fillColor ('ivp')}
          />
        </div>
        <div
          className={'product-type-controls-item'}
          style={
            _isSmallScreen && !isMobileMenuActive
              ? {display: 'none'}
              : {display: 'inherit'}
          }
          onClick={() => {
            props.selectionPicker ('ie');
            setIsMobileMenuActive (!isMobileMenuActive);
          }}
          onMouseEnter={() => props.colorHoverToggler ('ie')}
          onMouseLeave={() => props.colorHoverTogglerClear ('ie')}
        >
          <InteractiveExperiences iconFill={props.fillColor ('ie')} />
        </div>

        <div
          className={'product-type-controls-item'}
          style={
            _isSmallScreen && !isMobileMenuActive
              ? {display: 'none'}
              : {display: 'inherit'}
          }
          onClick={() => {
            props.selectionPicker ('avc');
            setIsMobileMenuActive (!isMobileMenuActive);
          }}
          onMouseEnter={() => props.colorHoverToggler ('avc')}
          onMouseLeave={() => props.colorHoverTogglerClear ('avc')}
        >
          <AdvancedVisualContent iconFill={props.fillColor ('avc')} />
        </div>

        <div className="product-type-controls-item">
          <div
            className={
              _isSmallScreen
                ? 'product-type-controls-legend-toggler'
                : 'product-type-controls-legend-toggler ' + legendToggler
            }
            style={
              isMobileMenuActive && _isSmallScreen
                ? {whiteSpace: 'normal'}
                : {whiteSpace: 'nowrap'}
            }
          >

            {!_isSmallScreen
              ? <ProductMessage
                  messages={props.messages}
                  iconSelected={
                    props.iconHover ? props.iconHover : props.iconSelected
                    //props.iconHover
                  }
                />
              : <ProductMessage
                  messages={props.messages}
                  iconSelected={
                    props.iconSelected === 'search'
                      ? iconSelectedBeforeSearchClick
                      : props.iconSelected
                    //props.iconHover
                  }
                />}
          </div>
        </div>
      </div>

      <div className="portfolio-search-box">
        {props.currentIconSelected === 'search'
          ? <SearchFormAutoComplete
              searchMessage={props.searchMessages}
              data={props.data}
              locale={props.locale}
              searchValue={props.searchValue}
              value={props.tagSelected}
              searchValueSetter={props.searchValueSetter}
              changeTagSelected={props.changeTagSelected}
            />
          : null}
        <div
          className="search-icon-container"
          onClick={() => {
            if (props.iconSelected !== 'search') {
              setIsMobileMenuActive (false);
              setIconSelectedBeforeSearchClick (props.iconSelected);
              props.selectionPicker ('search');
            } else {
              setIsMobileMenuActive (false);
              props.selectionPicker (iconSelectedBeforeSearchClick);
            }
          }}
          onMouseEnter={() => props.colorHoverToggler ('search')}
          onMouseLeave={() => props.colorHoverTogglerClear ('search')}
        >
          <Search iconFill={props.fillColor ('search')} />
        </div>
      </div>
    </div>
  );
};

export default ProductGridControls;
