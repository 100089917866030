import React from 'react';
import '../../styles/components/portfolio-gallery/portfolio-navigation-information.scss';
import _ from 'lodash';
import ProductMessage from '../Utils/ProductMessage';

function TagList (props) {
  const tagsFlatList = _.chain (props.data)
    .map (d => d.node.frontmatter)
    .filter (
      d =>
        d['language'] === props.locale &&
        d['listed'] === true &&
        d['template'] !== 'LP'
    )
    .map (d => d.solutionTag)
    .flatten ()
    .value ();

  var id = 0;

  const tagOcurrences = _.values (_.groupBy (tagsFlatList)).map (d => {
    id++;

    return {
      id: id - 1,
      tag: d[0],
      count: d.length,
    };
  });

  const sortedTop10Tags = _.chain (tagOcurrences)
    .sortBy (d => -d.count)
    .take (10)
    .map (d => {
      return (
        <div
          className="tag-item"
          key={d.id}
          onClick={() => props.changeTagSelected (d.tag)}
        >
          {d.tag}
        </div>
      );
    })
    .value ();

  return (
    <div className="tag-list">
      {sortedTop10Tags}
    </div>
  );
}

const PortfolioNavigationInformation = props => {
  const iconSelected = props.currentIconSelected;

  const informationType = iconSelected !== 'search'
    ? <div className="product-type-text">
        <h4>
          <ProductMessage
            messages={props.messages}
            iconSelected={iconSelected}
          />
        </h4>
      </div>
    : <TagList
        data={props.data}
        locale={props.locale}
        changeTagSelected={props.changeTagSelected}
      />;

  return (
    <div className="portfolio-navigation-information-block">

      {informationType}

    </div>
  );
};

export default PortfolioNavigationInformation;
