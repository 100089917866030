import React from 'react'

const ProductMessage = props => {
  let message = ''

  switch (props.iconSelected) {
    case 'all':
      message = props.messages.productsAll
      break
    case 'mls':
      message = props.messages.productsManagement
      break
    case 'ivp':
      message = props.messages.productsImmersive
      break
    case 'ie':
      message = props.messages.productsInteractive
      break
    case 'avc':
      message = props.messages.productsAdvancedVisual
      break
    case 'search':
      message = ''
      break
    default:
      message = props.messages.productsAll
  }

  return <div>{message}</div>
}

export default ProductMessage
