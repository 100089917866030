import React from 'react';
import {graphql, withPrefix} from 'gatsby';
import Layout from '../components/layout';
import PanoBanner from '../components/Pano/PanoBanner';
import '../styles/pages/index.scss';
import aliveBanner from '../images/alive_banner.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../styles/pages/perfect-scrollbar.scss';
import '../styles/utils/general.scss';
import {navigate} from '@reach/router';
import PortfolioGrid from '../components/PortfolioGallery/PortfolioGrid';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {makeScrollWaypoint} from '../utils/helpers';
import LocalizedLink from '../components/Utils/LocalizedLink';
import * as routes from '../utils/constants/routes';
import KrPanoInteractionLogo from '../components/Pano/KrPanoInteractionLogo';
import ScrollInteractionLogo from '../components/Pano/ScrollInteractionLogo';
import {isTouchDevice} from '../utils/helpers';
import {isSmallScreen} from '../utils/helpers';

class IndexPageAlpha extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      panoInteraction: false,
      scrollInteraction: false,
      pagination: {},
      logoColor: 'white',
      currentProjectInPano: {},
      productTypeShortSideMenu: null,
    };
  }

  resetProductTypeShortSideMenu = () => {
    this.setState ({
      productTypeShortSideMenu: null,
    });
  };

  /*
    booleans for handling interaction with 360 banner   
  */

  panoInteraction = () => {
    this.setState ({
      panoInteraction: true,
    });
  };

  scrollInteraction = value => {
    if (value === false) {
      this.setState ({
        scrollInteraction: false,
      });
    } else
      this.setState ({
        scrollInteraction: true,
      });
  };

  currentProjectInPanoSetter = project =>
    this.setState ({currentProjectInPano: project});

  /*
    scroll event for changing logo colors   
  */

  listenScrollEvent = e => {
    var portfolioWaypoint = makeScrollWaypoint ('.portfolio-container');

    if (portfolioWaypoint <= 0) {
      this.setState ({logoColor: '#0033a0'});
    } else {
      this.setState ({logoColor: 'white'});
    }
  };

  /*
    calculate pagination for scroll area   
  */

  getPagination = (currentPageProgress = 1) => {
    if (currentPageProgress < 1) {
      currentPageProgress = 1;
    }

    let contentDivHeight = document.getElementsByClassName ('content')[0]
      .clientHeight;

    let scrollAreaDivHeight = document.getElementById ('scrollbar-container')
      .offsetHeight;

    if (currentPageProgress > scrollAreaDivHeight) {
      currentPageProgress = scrollAreaDivHeight;
    }
    let totalPages = Math.ceil (contentDivHeight / scrollAreaDivHeight);

    let currentPage = Math.ceil (
      currentPageProgress / (scrollAreaDivHeight / totalPages)
    );

    this.setState ({
      pagination: {totalPages: totalPages, currentPage: currentPage},
    });
  };

  navigateToProjects = () => {
    var event = new CustomEvent ('closeLogin');

    window.dispatchEvent (event);

    setTimeout (() => {
      navigate ('#homepage-third-panel');
    }, 0);
  };

  navigateToAbout = () => {
    var event = new CustomEvent ('closeLogin');

    window.dispatchEvent (event);

    setTimeout (() => {
      navigate ('#homepage-second-panel');
    }, 0);
  };

  createBannerLinkMessage = project => {
    let message = '';

    if (project.banner3D) {
      if (this.props.pageContext.locale === 'en') {
        message = 'This 3D model belongs to project';
      } else {
        message = 'Este modelo 3D pertence ao projeto';
      }
    } else if (project.panoBannerImage) {
      if (this.props.pageContext.locale === 'en') {
        message = 'This panorama belongs to project';
      } else {
        message = 'Este panorama pertence ao projeto';
      }
    } else if (project.videoBannerId) {
      if (this.props.pageContext.locale === 'en') {
        message = 'This video belongs to project';
      } else {
        message = 'Este vídeo pertence ao projeto';
      }
    } else {
      if (this.props.pageContext.locale === 'en') {
        message = 'This image belongs to project';
      } else {
        message = 'Esta imagem pertence ao projeto';
      }
    }

    return message;
  };

  componentDidMount () {
    this.pagination = this.getPagination ();

    window.addEventListener ('scroll', this.listenScrollEvent);
    window.addEventListener ('navigateToProjects', this.navigateToProjects);
    window.addEventListener ('navigateToAbout', this.navigateToAbout);

    /** scroll to project gallery coming from cases footer
    or cases side menu
    */

    var event = new CustomEvent ('closeLogin');

    if (this.props.location.state) {
      this.setState ({
        productTypeShortSideMenu: this.props.location.state
          .productTypeShortFromSideMenu,
      });
    }

    if (this.props.location.state !== null) {
      setTimeout (() => {
        if (this.props.location.state.comesFromPageFooter)
          navigate ('#homepage-third-panel');
      }, 0);

      setTimeout (() => {
        if (this.props.location.state.productTypeShortFromSideMenu)
          navigate ('#homepage-third-panel');
      }, 0);

      if (this.props.location.state.comesFromMenuProjects) {
        window.dispatchEvent (event);

        setTimeout (() => {
          navigate ('#homepage-third-panel');
        }, 0);
      }

      if (this.props.location.state.comesFromMenuAboutUs) {
        window.dispatchEvent (event);

        setTimeout (() => {
          navigate ('#homepage-second-panel');
        }, 0);
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener ('scroll', this.listenScrollEvent);
    window.removeEventListener ('navigateToProjects', this.navigateToProjects);
    window.removeEventListener ('navigateToAbout', this.navigateToAbout);
  }

  render () {
    const messages = this.props.data.file.childIndexJson;

    return (
      <Layout
        path="/"
        locale={this.props.pageContext}
        headerLogoColor={this.state.logoColor}
      >
        <section id="homepage-first-panel" style={{position: 'relative'}}>
          <div
            className="pano-wrapper--index"
            onClick={
              typeof window !== `undefined` && !isTouchDevice ()
                ? this.panoInteraction
                : undefined
            }
            onTouchStart={
              typeof window !== `undefined` && isTouchDevice ()
                ? this.panoInteraction
                : undefined
            }
            onWheel={this.scrollInteraction}
          >
            <PanoBanner
              path={'/'}
              currentProjectInPanoSetter={this.currentProjectInPanoSetter}
              bannerXml={'random'}
              locale={this.props.pageContext}
              scrollInteraction={this.state.scrollInteraction}
              scrollInteractionSet={this.scrollInteraction}
            />
          </div>
          <div className="banner-link-project-index">
            <div className="banner-link-text shadow">
              {this.createBannerLinkMessage (this.state.currentProjectInPano) +
                ' ' +
                this.state.currentProjectInPano.title}
            </div>

            <button
              className="button-primary"
              onClick={() =>
                navigate (
                  this.props.pageContext.locale === 'pt'
                    ? withPrefix (
                        `/${this.props.pageContext.locale}/${this.state.currentProjectInPano.path}`
                      )
                    : withPrefix (this.state.currentProjectInPano.path)
                )}
            >
              {messages.bannerLinkButton}
            </button>
          </div>
          <div className="text-wrapper--index" onWheel={this.scrollInteraction}>
            <div className="main-title-wrapper--index">
              <img className="shadow" src={aliveBanner} alt="alive-banner" />
            </div>
            <div className="subtitle-wrapper--index shadow">
              <h4>Making Business Visually</h4>
            </div>

            <KrPanoInteractionLogo
              path={'/'}
              locale={this.props.pageContext.locale}
              panoBannerImage={this.state.currentProjectInPano.panoBannerImage}
              panoInteraction={this.state.panoInteraction}
            />
            <ScrollInteractionLogo
              scrollInteraction={this.state.scrollInteraction}
              locale={this.props.pageContext.locale}
              scrollInteractionSet={this.scrollInteraction}
            />
          </div>
        </section>
        <section id="homepage-second-panel">
          <div className="middle-wrapper">
            <div className="middle-wrapper-content-block">
              <div className="middle-wrapper-text-content">
                <div className="scroll-container-progress">
                  <p>
                    {this.state.pagination.currentPage +
                      '/' +
                      this.state.pagination.totalPages}
                  </p>
                </div>

                <div id="scrollbar-container">
                  <PerfectScrollbar
                    onScrollY={container =>
                      this.getPagination (container.scrollTop)}
                  >
                    <div className="content">
                      {isSmallScreen ()
                        ? <p style={{fontWeight: 300, fontSize: '28px'}}>
                            {messages.scrollText}
                          </p>
                        : <h2 style={{fontWeight: 300}}>
                            {messages.scrollText}
                          </h2>}
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
              <div className="parallax-navigation-container">
                <div className="parallax-navigation-container-item">
                  {messages.centerLink1}
                </div>
                <div className="parallax-navigation-container-item">
                  <AnchorLink href="#homepage-third-panel">
                    {messages.centerLink2}
                  </AnchorLink>
                </div>
                <div className="parallax-navigation-container-item">
                  <LocalizedLink
                    to={routes.CONTACTS}
                    locale={this.props.pageContext.locale}
                  >
                    {messages.centerLink3}
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="homepage-third-panel">
          <div className="portfolio-container--index">
            <div id="homepage-projects">
              <PortfolioGrid
                resetProductTypeShortSideMenu={
                  this.resetProductTypeShortSideMenu
                }
                productTypeShortSideMenu={this.state.productTypeShortSideMenu}
                locale={this.props.pageContext}
                messages={messages}
              />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query HomeAlpha($locale: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: "data/pages/index" }) {
      childIndexJson {
        krpanoInteractionLogo
        scrollText
        search
        hRefcenterLink1
        centerLink1
        centerLink2
        centerLink3
        productsImmersive
        productsAll
        productsManagement
        productsAdvancedVisual
        productsInteractive
        bannerLinkButton
      }
    }
  }
`;

export default IndexPageAlpha;

/*

const IndexPage = ({pageContext: {locale}, data}, props) => {
  return (
    <Layout path="/" locale={locale}>
      <div className="pano-wrapper--index">
        <PanoContainer />
      </div>
      <div className="text-wrapper--index">
        <div className="main-title-wrapper--index">
          <AliveVisionBanner />
        </div>
        <div className="subtitle-wrapper--index">
          <h4>Making Business Visually</h4>
        </div>
        <div className="krpano-activation--index">
          <div className="krpano-activation-icon--index">
            <BannerInteractionIcon />
          </div>

        </div>
        <div className="krpano-activation-caption--index">
          <p>Clique e arraste para explorar a <br /> imagem</p>
        </div>

        <div className="scroll-indicator--index">
          E
        </div>
      </div>

      <div className="spacer">
        AAA
      </div>

      <Link to="/page-2/">Go to page 2</Link>
    </Layout>
  );
};


 style={{ backgroundImage: 'url(' + backGroundImageMiddle + ')' }}










             <div className={'krpano-activation--index'}>
              <div
                className={
                  'krpano-activation-icon--index ' + krpanoIconIsHidden
                }
              >
                <div className="krpano-interaction-arrow-left--index">
                  <img src={arrowLeft} alt="navigation-arrow-left" />
                </div>
                <div className="krpano-interaction-central-icon--index">
                  <img src={krpanoCircleIcon} alt="navigation-krpano-circle" />
                </div>
                <div className="krpano-interaction-arrow-right--index">
                  <img src={arrowRight} alt="navigation-arrow-right" />
                </div>
              </div>
            </div>
            <div className={'krpano-activation-caption--index'}>
              <p className={krpanoIconIsHidden}>
                {messages.krpanoInteractionLogo}
              </p>
            </div>









<svg width="855px" height="101px" viewBox="0 0 855 101" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="path-1" points="0.684059137 0.244277922 73.320098 0.244277922 73.320098 92.7792208 0.684059137 92.7792208"></polygon>
        <polygon id="path-3" points="0 92.7792208 846.461332 92.7792208 846.461332 0.244155844 0 0.244155844"></polygon>
    </defs>
    <g id="Alive-Vision-Site" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="home" transform="translate(-293.000000, -338.000000)">
            <g id="ui-/-icon-/-logopedia-/-1_alive_logo-/-white" transform="translate(297.000000, 339.000000)">
                <g id="Group-19">
                    <polygon id="Fill-1" fill="#FFFFFF" points="51.9778917 2.43325714 12.993955 70.0693091 38.9828399 70.0693091 51.9778917 47.5239584 64.9729435 70.0693091 90.9618283 70.0693091"></polygon>
                    <polygon id="Fill-2" fill="#FFFFFF" points="0 92.615026 25.9888849 92.615026 12.9938331 70.0696753"></polygon>
                    <polygon id="Fill-3" fill="#FFFFFF" points="38.9836929 70.0696753 51.977526 92.615026 64.9725778 70.0696753"></polygon>
                    <polygon id="Fill-4" fill="#FFFFFF" points="77.9662891 92.615026 103.956393 92.615026 90.9613409 70.0696753"></polygon>
                    <polygon id="Fill-5" fill="#FFFFFF" points="482.017097 2.36220779 453.670018 67.8960779 425.578867 2.36220779 405.07903 2.36220779 445.571723 92.6144156 461.390515 92.6144156 501.884427 2.36220779"></polygon>
                    <polygon id="Fill-6" fill="#FFFFFF" points="508.970222 92.6140494 528.710806 92.6140494 528.710806 2.36184156 508.970222 2.36184156"></polygon>
                    <g id="Group-18" transform="translate(0.000000, 0.976257)">
                        <g id="Group-9" transform="translate(542.323741, 0.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlink:href="#path-1"></use>
                            </mask>
                            <g id="Clip-8"></g>
                            <path d="M16.3127325,90.0533429 C10.0266519,88.2380442 4.81668791,85.6805117 0.684059137,82.3844078 L7.01157568,68.1867455 C15.6997239,74.2723299 25.4018347,77.3145117 36.1166894,77.3145117 C41.853134,77.3145117 46.2599714,76.4062519 49.3408577,74.5897325 C52.4193066,72.7719922 53.9597498,70.2156805 53.9597498,66.9195766 C53.9597498,64.0483039 52.5460519,61.7862 49.7210937,60.1381481 C46.8924793,58.4900961 41.9384433,56.9055247 34.8516735,55.3856545 C26.9215599,53.7803299 20.6147613,51.8160961 15.9324965,49.4905117 C11.2502318,47.1673688 7.83420158,44.3376026 5.68318719,40.9975506 C3.5309541,37.6599403 2.45605626,33.4995247 2.45605626,28.5114208 C2.45605626,23.1045896 3.95262604,18.2446675 6.94820302,13.9340961 C9.94256129,9.62474545 14.1397814,6.26516104 19.5398635,3.85778442 C24.9375081,1.44918701 31.1821527,0.244277922 38.2689224,0.244277922 C44.6780922,0.244277922 50.8374275,1.17451169 56.7432721,3.03375844 C62.647898,4.89300519 67.3728174,7.42856364 70.9168117,10.639213 L64.5892951,24.8356545 C56.6579627,18.7512909 47.9271599,15.7091091 38.3944491,15.7091091 C33.3331671,15.7091091 29.2834102,16.7235766 26.2463973,18.7512909 C23.2093843,20.7790052 21.6908778,23.5269792 21.6908778,26.9903299 C21.6908778,29.0192649 22.2600131,30.7100442 23.3995023,32.0614468 C24.5377728,33.4140701 26.4560145,34.6189792 29.1566649,35.6737325 C31.8560965,36.7309273 35.6937987,37.7661481 40.6722088,38.7793948 C52.2304073,41.3149532 60.5602563,44.6098364 65.6654117,48.6664857 C70.7681296,52.7231351 73.320098,58.2581481 73.320098,65.2715247 C73.320098,73.7229792 70.0308131,80.420174 63.4510246,85.3631091 C56.8700174,90.3072649 47.7151052,92.7793429 35.989944,92.7793429 C29.1566649,92.7793429 22.5963757,91.8698623 16.3127325,90.0533429" id="Fill-7" fill="#FFFFFF" mask="url(#mask-2)"></path>
                        </g>
                        <mask id="mask-4" fill="white">
                            <use xlink:href="#path-3"></use>
                        </mask>
                        <g id="Clip-11"></g>
                        <polygon id="Fill-10" fill="#FFFFFF" mask="url(#mask-4)" points="629.943758 91.6377922 649.684342 91.6377922 649.684342 1.38558442 629.943758 1.38558442"></polygon>
                        <path d="M726.939639,69.3284182 C731.199013,64.0046 733.329309,56.357639 733.329309,46.3850935 C733.329309,36.4149896 731.199013,28.8095351 726.939639,23.5675091 C722.679046,18.3291455 716.752484,15.7093532 709.159951,15.7093532 C701.567419,15.7093532 695.639638,18.3291455 691.381482,23.5675091 C687.119671,28.8095351 684.989374,36.4149896 684.989374,46.3850935 C684.989374,56.4430935 687.119671,64.1108078 691.381482,69.3918987 C695.639638,74.6729896 701.567419,77.3147558 709.159951,77.3147558 C716.752484,77.3147558 722.679046,74.6522364 726.939639,69.3284182 M685.812,87.1383662 C679.188338,83.3783662 674.08562,77.9910675 670.50019,70.9764701 C666.91476,63.9630935 665.123263,55.765561 665.123263,46.3850935 C665.123263,37.004626 666.91476,28.8498208 670.50019,21.9206779 C674.08562,14.9915351 679.188338,9.64574286 685.812,5.88574286 C692.434443,2.12574286 700.217094,0.244522078 709.159951,0.244522078 C718.10159,0.244522078 725.864741,2.12574286 732.44453,5.88574286 C739.024318,9.64574286 744.086819,14.9915351 747.629594,21.9206779 C751.173589,28.8498208 752.944367,37.004626 752.944367,46.3850935 C752.944367,55.765561 751.173589,63.9630935 747.629594,70.9764701 C744.086819,77.9910675 739.024318,83.3783662 732.44453,87.1383662 C725.864741,90.899587 718.10159,92.779587 709.159951,92.779587 C700.217094,92.779587 692.434443,90.899587 685.812,87.1383662" id="Fill-12" fill="#FFFFFF" mask="url(#mask-4)"></path>
                        <polygon id="Fill-13" fill="#FFFFFF" mask="url(#mask-4)" points="827.858166 1.38570649 846.460479 1.38570649 846.460479 91.6379143 831.781177 91.6379143 786.858491 34.089161 786.858491 91.6379143 768.382922 91.6379143 768.382922 1.38570649 782.935479 1.38570649 827.858166 58.8074987"></polygon>
                        <polygon id="Fill-14" fill="#FFFFFF" mask="url(#mask-4)" points="112.940199 91.6388909 112.940199 1.38668312 132.680783 1.38668312 132.680783 75.5404753 172.922423 75.5404753 172.922423 91.6388909"></polygon>
                        <polygon id="Fill-15" fill="#FFFFFF" mask="url(#mask-4)" points="184.942145 91.639013 204.682729 91.639013 204.682729 1.38680519 184.942145 1.38680519"></polygon>
                        <polygon id="Fill-16" fill="#FFFFFF" mask="url(#mask-4)" points="288.83419 1.38656104 308.70152 1.38656104 268.207608 91.6387688 252.390035 91.6387688 211.894904 1.38656104 232.39596 1.38656104 260.48833 66.9204312"></polygon>
                        <polygon id="Fill-17" fill="#FFFFFF" mask="url(#mask-4)" points="315.786218 91.6388909 315.786218 1.38668312 377.919457 1.38668312 377.919457 16.7245532 335.021039 16.7245532 335.021039 38.019826 375.261461 38.019826 375.261461 53.4834364 335.021039 53.4834364 335.021039 76.3010208 377.919457 76.3010208 377.919457 91.6388909"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>




*/

/*

*/
